// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-views-1212-html-jsx": () => import("./../src/views/1212.html.jsx" /* webpackChunkName: "component---src-views-1212-html-jsx" */),
  "component---src-views-2019-1111-html-jsx": () => import("./../src/views/2019-1111.html.jsx" /* webpackChunkName: "component---src-views-2019-1111-html-jsx" */),
  "component---src-views-2020-1111-html-jsx": () => import("./../src/views/2020-1111.html.jsx" /* webpackChunkName: "component---src-views-2020-1111-html-jsx" */),
  "component---src-views-3-d-rendering-special-offer-html-jsx": () => import("./../src/views/3d-rendering-special-offer.html.jsx" /* webpackChunkName: "component---src-views-3-d-rendering-special-offer-html-jsx" */),
  "component---src-views-404-html-jsx": () => import("./../src/views/404.html.jsx" /* webpackChunkName: "component---src-views-404-html-jsx" */),
  "component---src-views-about-html-jsx": () => import("./../src/views/about.html.jsx" /* webpackChunkName: "component---src-views-about-html-jsx" */),
  "component---src-views-architectural-rendering-html-jsx": () => import("./../src/views/architectural-rendering.html.jsx" /* webpackChunkName: "component---src-views-architectural-rendering-html-jsx" */),
  "component---src-views-architectural-rendering-monthly-promotion-html-jsx": () => import("./../src/views/architectural-rendering-monthly-promotion.html.jsx" /* webpackChunkName: "component---src-views-architectural-rendering-monthly-promotion-html-jsx" */),
  "component---src-views-architectural-rendering-old-html-jsx": () => import("./../src/views/architectural-rendering-old.html.jsx" /* webpackChunkName: "component---src-views-architectural-rendering-old-html-jsx" */),
  "component---src-views-aspera-html-jsx": () => import("./../src/views/aspera.html.jsx" /* webpackChunkName: "component---src-views-aspera-html-jsx" */),
  "component---src-views-client-works-html-jsx": () => import("./../src/views/client-works.html.jsx" /* webpackChunkName: "component---src-views-client-works-html-jsx" */),
  "component---src-views-cloud-rendering-promotion-html-jsx": () => import("./../src/views/cloud-rendering-promotion.html.jsx" /* webpackChunkName: "component---src-views-cloud-rendering-promotion-html-jsx" */),
  "component---src-views-collaborative-cloud-production-html-jsx": () => import("./../src/views/collaborative-cloud-production.html.jsx" /* webpackChunkName: "component---src-views-collaborative-cloud-production-html-jsx" */),
  "component---src-views-download-html-jsx": () => import("./../src/views/download.html.jsx" /* webpackChunkName: "component---src-views-download-html-jsx" */),
  "component---src-views-effect-price-html-jsx": () => import("./../src/views/effect_price.html.jsx" /* webpackChunkName: "component---src-views-effect-price-html-jsx" */),
  "component---src-views-film-tv-rendering-html-jsx": () => import("./../src/views/film-tv-rendering.html.jsx" /* webpackChunkName: "component---src-views-film-tv-rendering-html-jsx" */),
  "component---src-views-go-cloud-html-jsx": () => import("./../src/views/go-cloud.html.jsx" /* webpackChunkName: "component---src-views-go-cloud-html-jsx" */),
  "component---src-views-gocloud-html-jsx": () => import("./../src/views/gocloud.html.jsx" /* webpackChunkName: "component---src-views-gocloud-html-jsx" */),
  "component---src-views-guoqing-2020-html-jsx": () => import("./../src/views/guoqing2020.html.jsx" /* webpackChunkName: "component---src-views-guoqing-2020-html-jsx" */),
  "component---src-views-index-html-jsx": () => import("./../src/views/index.html.jsx" /* webpackChunkName: "component---src-views-index-html-jsx" */),
  "component---src-views-nvidia-form-html-jsx": () => import("./../src/views/nvidia-form.html.jsx" /* webpackChunkName: "component---src-views-nvidia-form-html-jsx" */),
  "component---src-views-nzjh-czhd-html-jsx": () => import("./../src/views/nzjh-czhd.html.jsx" /* webpackChunkName: "component---src-views-nzjh-czhd-html-jsx" */),
  "component---src-views-partner-html-jsx": () => import("./../src/views/partner.html.jsx" /* webpackChunkName: "component---src-views-partner-html-jsx" */),
  "component---src-views-pricing-html-jsx": () => import("./../src/views/pricing.html.jsx" /* webpackChunkName: "component---src-views-pricing-html-jsx" */),
  "component---src-views-promotion-html-jsx": () => import("./../src/views/promotion.html.jsx" /* webpackChunkName: "component---src-views-promotion-html-jsx" */),
  "component---src-views-questionnaire-html-jsx": () => import("./../src/views/questionnaire.html.jsx" /* webpackChunkName: "component---src-views-questionnaire-html-jsx" */),
  "component---src-views-raytools-html-jsx": () => import("./../src/views/raytools.html.jsx" /* webpackChunkName: "component---src-views-raytools-html-jsx" */),
  "component---src-views-rc-2022-html-jsx": () => import("./../src/views/rc2022.html.jsx" /* webpackChunkName: "component---src-views-rc-2022-html-jsx" */),
  "component---src-views-rc-2024-detail-html-jsx": () => import("./../src/views/rc2024-detail.html.jsx" /* webpackChunkName: "component---src-views-rc-2024-detail-html-jsx" */),
  "component---src-views-rc-2024-gallery-html-jsx": () => import("./../src/views/rc2024-gallery.html.jsx" /* webpackChunkName: "component---src-views-rc-2024-gallery-html-jsx" */),
  "component---src-views-rc-2024-html-jsx": () => import("./../src/views/rc2024.html.jsx" /* webpackChunkName: "component---src-views-rc-2024-html-jsx" */),
  "component---src-views-rc-details-html-jsx": () => import("./../src/views/rc-details.html.jsx" /* webpackChunkName: "component---src-views-rc-details-html-jsx" */),
  "component---src-views-rc-form-html-jsx": () => import("./../src/views/rc-form.html.jsx" /* webpackChunkName: "component---src-views-rc-form-html-jsx" */),
  "component---src-views-rc-html-jsx": () => import("./../src/views/rc.html.jsx" /* webpackChunkName: "component---src-views-rc-html-jsx" */),
  "component---src-views-rc-submit-html-jsx": () => import("./../src/views/rc-submit.html.jsx" /* webpackChunkName: "component---src-views-rc-submit-html-jsx" */),
  "component---src-views-recharge-lottery-html-jsx": () => import("./../src/views/recharge-lottery.html.jsx" /* webpackChunkName: "component---src-views-recharge-lottery-html-jsx" */),
  "component---src-views-rendering-cluster-html-jsx": () => import("./../src/views/rendering-cluster.html.jsx" /* webpackChunkName: "component---src-views-rendering-cluster-html-jsx" */),
  "component---src-views-return-50-ps-html-jsx": () => import("./../src/views/return50ps.html.jsx" /* webpackChunkName: "component---src-views-return-50-ps-html-jsx" */),
  "component---src-views-start-new-year-html-jsx": () => import("./../src/views/start-new-year.html.jsx" /* webpackChunkName: "component---src-views-start-new-year-html-jsx" */),
  "component---src-views-user-evaluation-html-jsx": () => import("./../src/views/user-evaluation.html.jsx" /* webpackChunkName: "component---src-views-user-evaluation-html-jsx" */),
  "component---src-views-vfx-html-jsx": () => import("./../src/views/vfx.html.jsx" /* webpackChunkName: "component---src-views-vfx-html-jsx" */),
  "component---src-views-xgt-101-recharge-html-jsx": () => import("./../src/views/xgt-101-recharge.html.jsx" /* webpackChunkName: "component---src-views-xgt-101-recharge-html-jsx" */),
  "component---src-views-xgt-1212-html-jsx": () => import("./../src/views/xgt1212.html.jsx" /* webpackChunkName: "component---src-views-xgt-1212-html-jsx" */),
  "component---src-views-xgt-download-html-jsx": () => import("./../src/views/xgt-download.html.jsx" /* webpackChunkName: "component---src-views-xgt-download-html-jsx" */),
  "component---src-views-xgt-membership-month-html-jsx": () => import("./../src/views/xgt-membership-month.html.jsx" /* webpackChunkName: "component---src-views-xgt-membership-month-html-jsx" */),
  "component---src-views-xgt-xmas-html-jsx": () => import("./../src/views/xgt-xmas.html.jsx" /* webpackChunkName: "component---src-views-xgt-xmas-html-jsx" */),
  "component---src-views-xgtnzhd-html-jsx": () => import("./../src/views/xgtnzhd.html.jsx" /* webpackChunkName: "component---src-views-xgtnzhd-html-jsx" */),
  "component---src-views-ysdh-mid-year-recharge-html-jsx": () => import("./../src/views/ysdh-mid-year-recharge.html.jsx" /* webpackChunkName: "component---src-views-ysdh-mid-year-recharge-html-jsx" */),
  "component---src-views-ysdh-xrj-lqzn-html-jsx": () => import("./../src/views/ysdh-xrj-lqzn.html.jsx" /* webpackChunkName: "component---src-views-ysdh-xrj-lqzn-html-jsx" */),
  "component---src-views-ysdh-year-end-recharge-html-jsx": () => import("./../src/views/ysdh-year-end-recharge.html.jsx" /* webpackChunkName: "component---src-views-ysdh-year-end-recharge-html-jsx" */),
  "component---src-views-ysdhnzhd-html-jsx": () => import("./../src/views/ysdhnzhd.html.jsx" /* webpackChunkName: "component---src-views-ysdhnzhd-html-jsx" */)
}

